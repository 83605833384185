import React from "react";
import ImageWrapper from "./ImageWrapper";
import Link from "next/link";

function CmsCardGroup({ component, page_id = "" }) {
  if (component.id == "elenco-spezie") {
    return (
      <div className={``} id={`${component.id ?? ""}`}>
        {component.options.cards.map(function (card, i) {
          var componentStyle =
            card.image.url != ""
              ? {
                  backgroundImage: `url(${card.image.url})`,
                }
              : {};

          return (
            <div key={i}>
              <div className="card">
                <div className="image" style={componentStyle}>
                  <span>{card.link_text}</span>
                </div>
                <div
                  className="caption"
                  dangerouslySetInnerHTML={{ __html: card.caption }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div
        // className={`griglia grid-cols-${component.options.itemsPerRow}`}
        id={`${component.id ?? ""}`}
      >
        {component.options.cards.map(function (card, i) {
          if (card.url !== null) {
            return (
              <div key={i}>
                <Link href={card.url}>
                  <a
                    className="card-btn"
                    target={card.url.startsWith("http") ? "_blank" : "_self"}
                    rel={card.url.startsWith('/') ? '' : `noopener noreferrer`}
                  >
                    <div>
                      <div className="text-center card">
                        <div className="image relative">
                          {card.image.url != "" && (
                            <ImageWrapper
                              alt=""
                              src={card.image.url}
                              blur={card.image.urlp}
                              layout="responsive"
                              width={card.image.width}
                              height={card.image.height}
                              // priority={true}
                            />
                          )}
                        </div>
                        <div className="cardContent">
                          <div
                            className="caption"
                            dangerouslySetInnerHTML={{ __html: card.caption }}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            );
          } else {
            return (
              <div key={i}>
                <div>
                  <div className="text-center card">
                    <div className="image">
                      {card.image.url != "" && (
                        <ImageWrapper
                          alt=""
                          src={card.image.url}
                          blur={card.image.urlp}
                          layout="fixed"
                          width={card.image.width}
                          height={card.image.height}
                          // priority={true}
                        />
                      )}
                    </div>
                    <div className="cardContent">
                      <div
                        className="caption"
                        dangerouslySetInnerHTML={{ __html: card.caption }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default CmsCardGroup;
